<template>
  <form class="p-2 max-w-full md:max-w-2xl" @submit.prevent="handleSubmit">
    Please define a new password for your account.
    <br/>

    <div class="errorMessage" v-if="error && error.message">
      {{ error.message }}
    </div>

    <label v-for="field in fields" :key="field.label">
      {{ field.label }}
      <template v-if="error && error.response && error.response[field.prop]">
        <p class="text-background bg-red p-1"  v-for="(message, index) in error.response[field.prop]" :key="index">
          {{ message }}
        </p>
      </template>
      <br/>
      <input v-bind="field.attrs" v-model="formData[field.prop]" />
      {{ field.hint }}
    </label>

    <template v-if="validationErrors.length > 0">
      <p class="text-background bg-red p-1"  v-for="(error, index) in validationErrors" :key="index">
        {{ error.message }}
      </p>
    </template>
    <br/>

    <ui-button
      type='submit'
      v-if="!loading"
    >
      <span>Submit</span>
      <span class='icon icon-ui-login' />
    </ui-button>
    <ui-loader
      v-else
      message="waiting..."
      variant="font"
    />
    or return to <router-link :to="'/'">home</router-link>
 </form>
</template>

<script>
import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader.vue'

export default {
  name: 'FormResetPassword',
  components: {
    'ui-button': Button,
    'ui-loader': Loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object,
      default: () => ({
        message: '',
        response: null
      })
    }
  },
  data: () => ({
    formData: {
      password: '',
      confirm_password: ''
    },
    fields: [
      {
        label: 'Password',
        prop: 'password',
        attrs: {
          required: true,
          type: 'password',
          placeholder: 'Choose a password'
        },
        hint: '8 characters minimum, 1 uppercase character, 1 special character, 1 digit'
      },
      {
        label: 'Confirm your password',
        prop: 'confirm_password',
        attrs: {
          required: true,
          type: 'password',
          placeholder: 'Choose a password'
        },
        hint: 'Please re enter your password'
      }
    ],
    validationErrors: []
  }),
  computed: {
    equalPasswords () {
      return this.formData.password === this.formData.confirm_password
    }
  //   lengthValid () {
  //     return this.password.length >= 8
  //   },
  //   hasDigit () {
  //     return /[0-9]+/.test(this.password)
  //   },
  //   hasUpperCase () {
  //     return /[A-Z]+/.test(this.password)
  //   },
  //   hasSpecialChar () {
  //     return /[\W_]+/.test(this.password)
  //   },
  //   passwordValid () {
  //     return this.hasUpperCase && this.hasDigit && this.hasSpecialChar
  //   }
  },
  methods: {
    handleSubmit () {
      this.clearErrors()

      if (!this.equalPasswords) {
        this.validationErrors.push({
          message: 'Passwords must be equal'
        })
      }
      // if (!this.lengthValid) {
      //   this.validationErrors.push({ message: 'Password must be at least 8 characters long' })
      // }
      // if (!this.passwordValid) {
      //   this.validationErrors.push({
      //     message: 'Password must contain at least one digit, one uppercase and one special character'
      //   })
      // }
      if (!this.validationErrors.length) {
        this.$emit('submit', this.formData)
      }
    },
    clearErrors () {
      this.validationErrors = []
    }
  }
}
</script>

<style lang="postcss" scoped>
form {

  input {
    padding: $default-spacing;
  }

  input,
  label {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    display: block;
    width: 100%;
  }

  label {
    text-align: left;
  }

  .Button {
    width: 100%;
    margin-bottom: 1em;
    span.icon {
      font-size: 2rem;
    }
  }

  .errorMessage {
    background-color: $color-red;
    color: var(--color-primary-dark);
    padding: 1rem;
    font-size: 1.2rem;
    margin: 1em auto;
  }

  a {
    cursor: pointer;
  }
  .Loader {
    width: 100%;
    position: unset;
  }

}
</style>
