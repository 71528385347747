<template>
  <div class="d-flex w-100 flex-jc-c center">
    <div class="d-flex flex-jc-c flex-ai-center flex-direction-column" >
      <div class="w-100 mb-4" v-if="!config.loading">
        <ui-logos :logos="logos" />
        <h1>{{config.data.SITE_NAME}}</h1>
        <h2>{{config.data.SITE_DESCRIPTION}}</h2>
      </div>
      <div v-if="success">
        <p>
          A password reset email has been sent, please check your inbox.<br/>
          Click on the URL provided in the email and a enter a new password.
        </p>
        <router-link :to="{ name: 'login' }">Back to login page</router-link>
      </div>
      <form-lost-password
        v-else
        @submit="handleSubmit"
      />
      <div
        v-if="error"
        class="errorMessage"
      >{{ error }}</div>
    </div>
  </div>
</template>

<script>
import Logos from '@/components/ui/Logos/Logos.vue'
import FormLostPassword from '@/components/forms/LostPassword'
import apiService from '@/services/api'
import { vrsStoreConfig } from '@/store/config/store'

export default {
  components: {
    FormLostPassword,
    'ui-logos': Logos
  },
  data: () => ({
    config: vrsStoreConfig.state,
    error: null,
    success: false
  }),
  computed: {
    logos: vrsStoreConfig.computed.logos
  },
  methods: {
    async handleSubmit (data) {
      try {
        await apiService.requestResetPassword(data)
        this.success = true
      } catch (error) {
        this.error = 'An error occured. Please try again.'
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
 .errorMessage {
    background-color: $color-red;
    color: var(--color-primary-dark);
    padding: 1rem;
    font-size: 1.2rem;
    margin: 1em auto;
  }
</style>
