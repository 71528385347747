























import apiService from '@/services/api'
import FormResetPassword from '@/components/forms/ResetPassword.vue'
import { vrsStoreConfig } from '@/store/config/store'
import UiLogos from '@/components/ui/Logos/Logos.vue'
import { vrsStoreAuth } from '@/store/auth/store'

export default {
  components: {
    FormResetPassword,
    UiLogos
  },
  data () {
    return {
      token: null,
      success: null,
      redirect: false,
      config: vrsStoreConfig.state,
      auth: vrsStoreAuth.state
    }
  },
  computed: {
    logos: vrsStoreConfig.computed.logos
  },
  methods: {
    async handleSubmit (data) {
      await vrsStoreAuth.actions.resetPassword({
        ...data,
        token: this.token
      })
      if (!vrsStoreAuth.state.error) {
        this.success = true
      }
    }
  },
  watch: {
    redirect () {
      this.redirect && this.$router.replace('login')
    }
  },
  beforeMount () {
    const { token } = this.$route.query
    if (!token) {
      this.redirect = true
    } else {
      this.token = token
    }
  },
  async mounted () {
    vrsStoreAuth.state.error = null
    try {
      await apiService.validateToken({ token: this.token })
    } catch (error) {
      this.redirect = true
    }
  }
}
