<template>
  <form class="p-2 max-w-full md:max-w-2xl" @submit.prevent="handleSubmit">
    Did you forget your password ?
    <br/>
    Please enter your email and click on submit.
    <br/>
    If you have a valid account, you will receive an email to reset your password.
    <br/>

    <label for="">email</label>
    <input type="email" v-model="email">
    <ui-button
      type='submit'
      v-if="!loading"
    >
      <span>Submit</span>
      <span class='icon icon-ui-login' />
    </ui-button>
    <ui-loader
      v-else
      message="waiting..."
      variant="font"
    />
    or return to <router-link :to="'/'">home</router-link>
  </form>
</template>

<script>
import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader.vue'

export default {
  name: 'FormLostPassword',
  props: {
    loading: Boolean
  },
  components: {
    'ui-button': Button,
    'ui-loader': Loader
  },
  data () {
    return {
      email: null
    }
  },
  methods: {
    handleSubmit () {
      this.$emit('submit', { email: this.email })
    }
  }
}
</script>

<style lang="postcss" scoped>
form {

  input {
    padding: $default-spacing;
  }

  input,
  label {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    display: block;
    width: 100%;
  }

  label {
    text-align: left;
  }

  .Button {
    width: 100%;
    margin-bottom: 1em;
    span.icon {
      font-size: 2rem;
    }
  }

  a {
    cursor: pointer;
  }
  .Loader {
    width: 100%;
    position: unset;
  }

}
</style>
